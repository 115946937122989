import { Injectable } from '@angular/core';
import { CountDto } from '@api-client';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

@Injectable({ providedIn: 'root' })
export class DayService {
  /**
   * 오늘 날짜를 YYYY-MM-DD 형태로 반환하는 함수
   * parameter 입력 시 입력 값 만큼 일수 제외 하고 반환
   * parameter가 존재하지 않을 시, 오늘 날짜 반환
   * @param num
   * @returns
   */
  getDate(num?: number) {

    if (!num) {
      const today = dayjs().format('YYYY-MM-DD');
      return today;
    }

    return dayjs().subtract(num, 'day').format('YYYY-MM-DD');
  }

  dateChangeToString(date?: Date, ) {
    if (!date) {
      const today = dayjs().format('YYYY-MM-DD');
      return today;
    }

    return dayjs(date).format('YYYY-MM-DD');
  }
}
