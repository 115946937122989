import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { IonModal } from '@ionic/angular/standalone';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-alert',
  imports: [CommonModule, ButtonComponent],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class AlertComponent {
  dialogData = inject(DIALOG_DATA);
  dialogRef = inject(DialogRef);
  modal!: IonModal;

  title = this.dialogData.title;
  content = this.dialogData.content;
  isChecked = this.dialogData.isChecked || false;
  isErrord = this.dialogData.isErrord || false;
  icons: string | undefined = this.dialogData.icons || undefined;

  dismiss() {
    this.dialogRef.close();
  }
}
