import { Pipe, PipeTransform } from '@angular/core';
import { CouponType } from '@common';
import dayjs from 'dayjs';

@Pipe({
  name: 'adminDatePipe',
  standalone: true,
})
export class adminDatePipe implements PipeTransform {
  transform(value: Date | null) {
    if (value) return this.dateParse(value);

    return '-';
  }

  dateParse(date: Date) {
    return dayjs(date).format('YYYY-MM-DD');
  }
}
