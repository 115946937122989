/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { coBuyingCategoryControllerCreate } from '../fn/co-buying-category/co-buying-category-controller-create';
import { CoBuyingCategoryControllerCreate$Params } from '../fn/co-buying-category/co-buying-category-controller-create';
import { coBuyingCategoryControllerDelete } from '../fn/co-buying-category/co-buying-category-controller-delete';
import { CoBuyingCategoryControllerDelete$Params } from '../fn/co-buying-category/co-buying-category-controller-delete';
import { coBuyingCategoryControllerFindAll } from '../fn/co-buying-category/co-buying-category-controller-find-all';
import { CoBuyingCategoryControllerFindAll$Params } from '../fn/co-buying-category/co-buying-category-controller-find-all';
import { coBuyingCategoryControllerUpdate } from '../fn/co-buying-category/co-buying-category-controller-update';
import { CoBuyingCategoryControllerUpdate$Params } from '../fn/co-buying-category/co-buying-category-controller-update';
import { CoBuyingCategoryDto } from '../models/co-buying-category-dto';

@Injectable({ providedIn: 'root' })
export class CoBuyingCategoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `coBuyingCategoryControllerFindAll()` */
  static readonly CoBuyingCategoryControllerFindAllPath = '/api/co-buying-category';

  /**
   * 공동구매 카테고리 조회.
   *
   * 공동구매 카테고리를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingCategoryControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingCategoryControllerFindAll$Response(params?: CoBuyingCategoryControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CoBuyingCategoryDto>>> {
    return coBuyingCategoryControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 공동구매 카테고리 조회.
   *
   * 공동구매 카테고리를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingCategoryControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingCategoryControllerFindAll(params?: CoBuyingCategoryControllerFindAll$Params, context?: HttpContext): Observable<Array<CoBuyingCategoryDto>> {
    return this.coBuyingCategoryControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CoBuyingCategoryDto>>): Array<CoBuyingCategoryDto> => r.body)
    );
  }

  /** Path part for operation `coBuyingCategoryControllerCreate()` */
  static readonly CoBuyingCategoryControllerCreatePath = '/api/co-buying-category';

  /**
   * 공동구매 카테고리 생성.
   *
   * 공동구매 카테고리를 생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingCategoryControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  coBuyingCategoryControllerCreate$Response(params: CoBuyingCategoryControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<CoBuyingCategoryDto>> {
    return coBuyingCategoryControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 공동구매 카테고리 생성.
   *
   * 공동구매 카테고리를 생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingCategoryControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  coBuyingCategoryControllerCreate(params: CoBuyingCategoryControllerCreate$Params, context?: HttpContext): Observable<CoBuyingCategoryDto> {
    return this.coBuyingCategoryControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoBuyingCategoryDto>): CoBuyingCategoryDto => r.body)
    );
  }

  /** Path part for operation `coBuyingCategoryControllerDelete()` */
  static readonly CoBuyingCategoryControllerDeletePath = '/api/co-buying-category/{id}';

  /**
   * 공동구매 카테고리 삭제.
   *
   * 공동구매 카테고리를 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingCategoryControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingCategoryControllerDelete$Response(params: CoBuyingCategoryControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<CoBuyingCategoryDto>> {
    return coBuyingCategoryControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 공동구매 카테고리 삭제.
   *
   * 공동구매 카테고리를 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingCategoryControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingCategoryControllerDelete(params: CoBuyingCategoryControllerDelete$Params, context?: HttpContext): Observable<CoBuyingCategoryDto> {
    return this.coBuyingCategoryControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoBuyingCategoryDto>): CoBuyingCategoryDto => r.body)
    );
  }

  /** Path part for operation `coBuyingCategoryControllerUpdate()` */
  static readonly CoBuyingCategoryControllerUpdatePath = '/api/co-buying-category/{id}';

  /**
   * 공동구매 카테고리 수정.
   *
   * 공동구매 카테고리를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingCategoryControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  coBuyingCategoryControllerUpdate$Response(params: CoBuyingCategoryControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<CoBuyingCategoryDto>> {
    return coBuyingCategoryControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 공동구매 카테고리 수정.
   *
   * 공동구매 카테고리를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingCategoryControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  coBuyingCategoryControllerUpdate(params: CoBuyingCategoryControllerUpdate$Params, context?: HttpContext): Observable<CoBuyingCategoryDto> {
    return this.coBuyingCategoryControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoBuyingCategoryDto>): CoBuyingCategoryDto => r.body)
    );
  }

}
