import { Component, effect, input, output } from '@angular/core';

@Component({
  selector: 'app-modal-naver-map-marker',
  standalone: true,
  templateUrl: './modal-naver-map-marker.component.html',
  styleUrl: './modal-naver-map-marker.component.scss',
})
export class ModalNaverMapMarkerComponent {
  changed = output<void>();

  lat = input<number>();
  lng = input<number>();

  constructor() {
    effect(() => {
      this.lat();
      this.lng();
      this.changed.emit();
    });
  }
}
