/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AdminService } from './services/admin.service';
import { MemberService } from './services/member.service';
import { OAuthService } from './services/o-auth.service';
import { ReservationService } from './services/reservation.service';
import { AttractionService } from './services/attraction.service';
import { StorageService } from './services/storage.service';
import { AttractionReviewService } from './services/attraction-review.service';
import { ProductService } from './services/product.service';
import { OrderService } from './services/order.service';
import { PointLogService } from './services/point-log.service';
import { CouponService } from './services/coupon.service';
import { StatService } from './services/stat.service';
import { NotificationService } from './services/notification.service';
import { LogService } from './services/log.service';
import { SettingService } from './services/setting.service';
import { GeocodeService } from './services/geocode.service';
import { InquiryService } from './services/inquiry.service';
import { FaqService } from './services/faq.service';
import { CouponLogService } from './services/coupon-log.service';
import { VersionService } from './services/version.service';
import { CoBuyingService } from './services/co-buying.service';
import { CartService } from './services/cart.service';
import { AddressService } from './services/address.service';
import { PaymentService } from './services/payment.service';
import { CoBuyingCategoryService } from './services/co-buying-category.service';
import { CoBuyingApplyService } from './services/co-buying-apply.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AdminService,
    MemberService,
    OAuthService,
    ReservationService,
    AttractionService,
    StorageService,
    AttractionReviewService,
    ProductService,
    OrderService,
    PointLogService,
    CouponService,
    StatService,
    NotificationService,
    LogService,
    SettingService,
    GeocodeService,
    InquiryService,
    FaqService,
    CouponLogService,
    VersionService,
    CoBuyingService,
    CartService,
    AddressService,
    PaymentService,
    CoBuyingCategoryService,
    CoBuyingApplyService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
