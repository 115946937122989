import { DatePipe, DecimalPipe, NgClass } from '@angular/common';
import { booleanAttribute, Component, input, output } from '@angular/core';
import { CouponLogDto } from '@api-client';
import { IconComponent } from '../icon/icon.component';
import dayjs from 'dayjs';

@Component({
  selector: 'app-coupon-log-item',
  imports: [DecimalPipe, DatePipe, IconComponent, NgClass],
  templateUrl: './coupon-log-item.component.html',
  styleUrl: './coupon-log-item.component.scss',
})
export class CouponLogItemComponent {
  use = output<void>();

  coupon = input.required<CouponLogDto>();
  button = input<boolean, string | boolean>(true, { transform: booleanAttribute });

  getEndedDate(date: Date) {
    return dayjs(date).add(this.coupon().coupon.endedDate, 'day').format('YYYY. MM. DD');
  }
}
