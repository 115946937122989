/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AttractionCategoryDto } from '../models/attraction-category-dto';
import { attractionControllerCreate } from '../fn/attraction/attraction-controller-create';
import { AttractionControllerCreate$Params } from '../fn/attraction/attraction-controller-create';
import { attractionControllerDelete } from '../fn/attraction/attraction-controller-delete';
import { AttractionControllerDelete$Params } from '../fn/attraction/attraction-controller-delete';
import { attractionControllerFavorite } from '../fn/attraction/attraction-controller-favorite';
import { AttractionControllerFavorite$Params } from '../fn/attraction/attraction-controller-favorite';
import { attractionControllerFindById } from '../fn/attraction/attraction-controller-find-by-id';
import { AttractionControllerFindById$Params } from '../fn/attraction/attraction-controller-find-by-id';
import { attractionControllerFindByName } from '../fn/attraction/attraction-controller-find-by-name';
import { AttractionControllerFindByName$Params } from '../fn/attraction/attraction-controller-find-by-name';
import { attractionControllerGetAll } from '../fn/attraction/attraction-controller-get-all';
import { AttractionControllerGetAll$Params } from '../fn/attraction/attraction-controller-get-all';
import { attractionControllerGetCategories } from '../fn/attraction/attraction-controller-get-categories';
import { AttractionControllerGetCategories$Params } from '../fn/attraction/attraction-controller-get-categories';
import { attractionControllerGetFavorite } from '../fn/attraction/attraction-controller-get-favorite';
import { AttractionControllerGetFavorite$Params } from '../fn/attraction/attraction-controller-get-favorite';
import { attractionControllerGetNearby } from '../fn/attraction/attraction-controller-get-nearby';
import { AttractionControllerGetNearby$Params } from '../fn/attraction/attraction-controller-get-nearby';
import { attractionControllerSearchCursor } from '../fn/attraction/attraction-controller-search-cursor';
import { AttractionControllerSearchCursor$Params } from '../fn/attraction/attraction-controller-search-cursor';
import { attractionControllerSearchOffset } from '../fn/attraction/attraction-controller-search-offset';
import { AttractionControllerSearchOffset$Params } from '../fn/attraction/attraction-controller-search-offset';
import { attractionControllerUpdate } from '../fn/attraction/attraction-controller-update';
import { AttractionControllerUpdate$Params } from '../fn/attraction/attraction-controller-update';
import { attractionControllerUpdateToViews } from '../fn/attraction/attraction-controller-update-to-views';
import { AttractionControllerUpdateToViews$Params } from '../fn/attraction/attraction-controller-update-to-views';
import { AttractionDto } from '../models/attraction-dto';
import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';

@Injectable({ providedIn: 'root' })
export class AttractionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `attractionControllerGetCategories()` */
  static readonly AttractionControllerGetCategoriesPath = '/api/attraction/category';

  /**
   * 카테고리 조회.
   *
   * 관광지의 카테고리들을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionControllerGetCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerGetCategories$Response(params?: AttractionControllerGetCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AttractionCategoryDto>>> {
    return attractionControllerGetCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * 카테고리 조회.
   *
   * 관광지의 카테고리들을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionControllerGetCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerGetCategories(params?: AttractionControllerGetCategories$Params, context?: HttpContext): Observable<Array<AttractionCategoryDto>> {
    return this.attractionControllerGetCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AttractionCategoryDto>>): Array<AttractionCategoryDto> => r.body)
    );
  }

  /** Path part for operation `attractionControllerGetFavorite()` */
  static readonly AttractionControllerGetFavoritePath = '/api/attraction/favorite';

  /**
   * 즐겨찾기한 관광지 조회.
   *
   * 즐겨찾기한 관광지를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionControllerGetFavorite()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerGetFavorite$Response(params?: AttractionControllerGetFavorite$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AttractionDto>>> {
    return attractionControllerGetFavorite(this.http, this.rootUrl, params, context);
  }

  /**
   * 즐겨찾기한 관광지 조회.
   *
   * 즐겨찾기한 관광지를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionControllerGetFavorite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerGetFavorite(params?: AttractionControllerGetFavorite$Params, context?: HttpContext): Observable<Array<AttractionDto>> {
    return this.attractionControllerGetFavorite$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AttractionDto>>): Array<AttractionDto> => r.body)
    );
  }

  /** Path part for operation `attractionControllerSearchOffset()` */
  static readonly AttractionControllerSearchOffsetPath = '/api/attraction/search/offset';

  /**
   * 관광지 검색.
   *
   * 관광지를 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerSearchOffset$Response(params: AttractionControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<AttractionDto>;
}>> {
    return attractionControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 관광지 검색.
   *
   * 관광지를 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerSearchOffset(params: AttractionControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto & {
'items'?: Array<AttractionDto>;
}> {
    return this.attractionControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<AttractionDto>;
}>): OffsetPaginationDto & {
'items'?: Array<AttractionDto>;
} => r.body)
    );
  }

  /** Path part for operation `attractionControllerSearchCursor()` */
  static readonly AttractionControllerSearchCursorPath = '/api/attraction/search/cursor';

  /**
   * 관광지 검색.
   *
   * 관광지를 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerSearchCursor$Response(params: AttractionControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto & {
'items'?: Array<AttractionDto>;
}>> {
    return attractionControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 관광지 검색.
   *
   * 관광지를 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerSearchCursor(params: AttractionControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto & {
'items'?: Array<AttractionDto>;
}> {
    return this.attractionControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto & {
'items'?: Array<AttractionDto>;
}>): CursorPaginationDto & {
'items'?: Array<AttractionDto>;
} => r.body)
    );
  }

  /** Path part for operation `attractionControllerGetNearby()` */
  static readonly AttractionControllerGetNearbyPath = '/api/attraction/nearby';

  /**
   * 주변 관광지 조회.
   *
   * 입력한 위도, 경도에 해당하는 주변 관광지를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionControllerGetNearby()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerGetNearby$Response(params: AttractionControllerGetNearby$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AttractionDto>>> {
    return attractionControllerGetNearby(this.http, this.rootUrl, params, context);
  }

  /**
   * 주변 관광지 조회.
   *
   * 입력한 위도, 경도에 해당하는 주변 관광지를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionControllerGetNearby$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerGetNearby(params: AttractionControllerGetNearby$Params, context?: HttpContext): Observable<Array<AttractionDto>> {
    return this.attractionControllerGetNearby$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AttractionDto>>): Array<AttractionDto> => r.body)
    );
  }

  /** Path part for operation `attractionControllerGetAll()` */
  static readonly AttractionControllerGetAllPath = '/api/attraction/all';

  /**
   * 모든 관광지 조회.
   *
   * (관리자) 모든 관광지를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionControllerGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerGetAll$Response(params?: AttractionControllerGetAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AttractionDto>>> {
    return attractionControllerGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 모든 관광지 조회.
   *
   * (관리자) 모든 관광지를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionControllerGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerGetAll(params?: AttractionControllerGetAll$Params, context?: HttpContext): Observable<Array<AttractionDto>> {
    return this.attractionControllerGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AttractionDto>>): Array<AttractionDto> => r.body)
    );
  }

  /** Path part for operation `attractionControllerFindById()` */
  static readonly AttractionControllerFindByIdPath = '/api/attraction/{id}';

  /**
   * 관광지 조회.
   *
   * 입력한 ID에 해당하는 관광지를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerFindById$Response(params: AttractionControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<AttractionDto>> {
    return attractionControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 관광지 조회.
   *
   * 입력한 ID에 해당하는 관광지를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerFindById(params: AttractionControllerFindById$Params, context?: HttpContext): Observable<AttractionDto> {
    return this.attractionControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttractionDto>): AttractionDto => r.body)
    );
  }

  /** Path part for operation `attractionControllerDelete()` */
  static readonly AttractionControllerDeletePath = '/api/attraction/{id}';

  /**
   * 관광지 삭제.
   *
   * 입력한 ID에 해당하는 관광지를 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerDelete$Response(params: AttractionControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return attractionControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 관광지 삭제.
   *
   * 입력한 ID에 해당하는 관광지를 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerDelete(params: AttractionControllerDelete$Params, context?: HttpContext): Observable<void> {
    return this.attractionControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `attractionControllerUpdate()` */
  static readonly AttractionControllerUpdatePath = '/api/attraction/{id}';

  /**
   * 관광지 수정.
   *
   * 입력한 ID에 해당하는 관광지를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attractionControllerUpdate$Response(params: AttractionControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<AttractionDto>> {
    return attractionControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 관광지 수정.
   *
   * 입력한 ID에 해당하는 관광지를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attractionControllerUpdate(params: AttractionControllerUpdate$Params, context?: HttpContext): Observable<AttractionDto> {
    return this.attractionControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttractionDto>): AttractionDto => r.body)
    );
  }

  /** Path part for operation `attractionControllerFindByName()` */
  static readonly AttractionControllerFindByNamePath = '/api/attraction/name/{name}';

  /**
   * 관광지 이름으로 조회.
   *
   * 입력한 이름에 해당하는 관광지를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionControllerFindByName()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerFindByName$Response(params: AttractionControllerFindByName$Params, context?: HttpContext): Observable<StrictHttpResponse<AttractionDto>> {
    return attractionControllerFindByName(this.http, this.rootUrl, params, context);
  }

  /**
   * 관광지 이름으로 조회.
   *
   * 입력한 이름에 해당하는 관광지를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionControllerFindByName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerFindByName(params: AttractionControllerFindByName$Params, context?: HttpContext): Observable<AttractionDto> {
    return this.attractionControllerFindByName$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttractionDto>): AttractionDto => r.body)
    );
  }

  /** Path part for operation `attractionControllerCreate()` */
  static readonly AttractionControllerCreatePath = '/api/attraction';

  /**
   * 관광지 등록.
   *
   * 관광지를 등록합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attractionControllerCreate$Response(params: AttractionControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<AttractionDto>> {
    return attractionControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 관광지 등록.
   *
   * 관광지를 등록합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attractionControllerCreate(params: AttractionControllerCreate$Params, context?: HttpContext): Observable<AttractionDto> {
    return this.attractionControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttractionDto>): AttractionDto => r.body)
    );
  }

  /** Path part for operation `attractionControllerFavorite()` */
  static readonly AttractionControllerFavoritePath = '/api/attraction/{id}/favorite';

  /**
   * 즐겨찾기.
   *
   * 입력한 ID에 해당하는 관광지를 즐겨찾기합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionControllerFavorite()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerFavorite$Response(params: AttractionControllerFavorite$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return attractionControllerFavorite(this.http, this.rootUrl, params, context);
  }

  /**
   * 즐겨찾기.
   *
   * 입력한 ID에 해당하는 관광지를 즐겨찾기합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionControllerFavorite$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerFavorite(params: AttractionControllerFavorite$Params, context?: HttpContext): Observable<void> {
    return this.attractionControllerFavorite$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `attractionControllerUpdateToViews()` */
  static readonly AttractionControllerUpdateToViewsPath = '/api/attraction/views/{id}';

  /**
   * 관광지 조회수 증가.
   *
   * 입력한 ID에 해당하는 관광지의 조회수를 증가합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attractionControllerUpdateToViews()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerUpdateToViews$Response(params: AttractionControllerUpdateToViews$Params, context?: HttpContext): Observable<StrictHttpResponse<AttractionDto>> {
    return attractionControllerUpdateToViews(this.http, this.rootUrl, params, context);
  }

  /**
   * 관광지 조회수 증가.
   *
   * 입력한 ID에 해당하는 관광지의 조회수를 증가합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `attractionControllerUpdateToViews$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  attractionControllerUpdateToViews(params: AttractionControllerUpdateToViews$Params, context?: HttpContext): Observable<AttractionDto> {
    return this.attractionControllerUpdateToViews$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttractionDto>): AttractionDto => r.body)
    );
  }

}
