/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { ProductCategoryDto } from '../models/product-category-dto';
import { productControllerCreate } from '../fn/product/product-controller-create';
import { ProductControllerCreate$Params } from '../fn/product/product-controller-create';
import { productControllerDelete } from '../fn/product/product-controller-delete';
import { ProductControllerDelete$Params } from '../fn/product/product-controller-delete';
import { productControllerFindById } from '../fn/product/product-controller-find-by-id';
import { ProductControllerFindById$Params } from '../fn/product/product-controller-find-by-id';
import { productControllerFindByName } from '../fn/product/product-controller-find-by-name';
import { ProductControllerFindByName$Params } from '../fn/product/product-controller-find-by-name';
import { productControllerGetAll } from '../fn/product/product-controller-get-all';
import { ProductControllerGetAll$Params } from '../fn/product/product-controller-get-all';
import { productControllerGetCategories } from '../fn/product/product-controller-get-categories';
import { ProductControllerGetCategories$Params } from '../fn/product/product-controller-get-categories';
import { productControllerSearchCursor } from '../fn/product/product-controller-search-cursor';
import { ProductControllerSearchCursor$Params } from '../fn/product/product-controller-search-cursor';
import { productControllerSearchOffset } from '../fn/product/product-controller-search-offset';
import { ProductControllerSearchOffset$Params } from '../fn/product/product-controller-search-offset';
import { productControllerUpdate } from '../fn/product/product-controller-update';
import { ProductControllerUpdate$Params } from '../fn/product/product-controller-update';
import { productControllerUpdateToViews } from '../fn/product/product-controller-update-to-views';
import { ProductControllerUpdateToViews$Params } from '../fn/product/product-controller-update-to-views';
import { ProductDto } from '../models/product-dto';

@Injectable({ providedIn: 'root' })
export class ProductService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `productControllerGetCategories()` */
  static readonly ProductControllerGetCategoriesPath = '/api/product/category';

  /**
   * 카테고리 조회.
   *
   * 등록된 상품의 카테고리들을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productControllerGetCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  productControllerGetCategories$Response(params?: ProductControllerGetCategories$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductCategoryDto>>> {
    return productControllerGetCategories(this.http, this.rootUrl, params, context);
  }

  /**
   * 카테고리 조회.
   *
   * 등록된 상품의 카테고리들을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productControllerGetCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productControllerGetCategories(params?: ProductControllerGetCategories$Params, context?: HttpContext): Observable<Array<ProductCategoryDto>> {
    return this.productControllerGetCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductCategoryDto>>): Array<ProductCategoryDto> => r.body)
    );
  }

  /** Path part for operation `productControllerSearchOffset()` */
  static readonly ProductControllerSearchOffsetPath = '/api/product/search/offset';

  /**
   * 상품 검색.
   *
   * 상품을 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  productControllerSearchOffset$Response(params: ProductControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<ProductDto>;
}>> {
    return productControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 상품 검색.
   *
   * 상품을 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productControllerSearchOffset(params: ProductControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto & {
'items'?: Array<ProductDto>;
}> {
    return this.productControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<ProductDto>;
}>): OffsetPaginationDto & {
'items'?: Array<ProductDto>;
} => r.body)
    );
  }

  /** Path part for operation `productControllerSearchCursor()` */
  static readonly ProductControllerSearchCursorPath = '/api/product/search/cursor';

  /**
   * 상품 검색.
   *
   * 상품을 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  productControllerSearchCursor$Response(params: ProductControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto & {
'items'?: Array<ProductDto>;
}>> {
    return productControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 상품 검색.
   *
   * 상품을 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productControllerSearchCursor(params: ProductControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto & {
'items'?: Array<ProductDto>;
}> {
    return this.productControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto & {
'items'?: Array<ProductDto>;
}>): CursorPaginationDto & {
'items'?: Array<ProductDto>;
} => r.body)
    );
  }

  /** Path part for operation `productControllerGetAll()` */
  static readonly ProductControllerGetAllPath = '/api/product/all';

  /**
   * 모든 상품 조회.
   *
   * (관리자) 모든 상품을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productControllerGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  productControllerGetAll$Response(params?: ProductControllerGetAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProductDto>>> {
    return productControllerGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 모든 상품 조회.
   *
   * (관리자) 모든 상품을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productControllerGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productControllerGetAll(params?: ProductControllerGetAll$Params, context?: HttpContext): Observable<Array<ProductDto>> {
    return this.productControllerGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProductDto>>): Array<ProductDto> => r.body)
    );
  }

  /** Path part for operation `productControllerFindById()` */
  static readonly ProductControllerFindByIdPath = '/api/product/{id}';

  /**
   * 상품 조회.
   *
   * 상품 정보를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  productControllerFindById$Response(params: ProductControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDto>> {
    return productControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 상품 조회.
   *
   * 상품 정보를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productControllerFindById(params: ProductControllerFindById$Params, context?: HttpContext): Observable<ProductDto> {
    return this.productControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDto>): ProductDto => r.body)
    );
  }

  /** Path part for operation `productControllerDelete()` */
  static readonly ProductControllerDeletePath = '/api/product/{id}';

  /**
   * 상품 삭제.
   *
   * 상품을 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  productControllerDelete$Response(params: ProductControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return productControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 상품 삭제.
   *
   * 상품을 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productControllerDelete(params: ProductControllerDelete$Params, context?: HttpContext): Observable<void> {
    return this.productControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `productControllerUpdate()` */
  static readonly ProductControllerUpdatePath = '/api/product/{id}';

  /**
   * 상품 수정.
   *
   * 상품 정보를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  productControllerUpdate$Response(params: ProductControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDto>> {
    return productControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 상품 수정.
   *
   * 상품 정보를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  productControllerUpdate(params: ProductControllerUpdate$Params, context?: HttpContext): Observable<ProductDto> {
    return this.productControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDto>): ProductDto => r.body)
    );
  }

  /** Path part for operation `productControllerFindByName()` */
  static readonly ProductControllerFindByNamePath = '/api/product/name/{name}';

  /**
   * 상품 이름으로 조회.
   *
   * 상품 이름으로 상품 정보를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productControllerFindByName()` instead.
   *
   * This method doesn't expect any request body.
   */
  productControllerFindByName$Response(params: ProductControllerFindByName$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDto>> {
    return productControllerFindByName(this.http, this.rootUrl, params, context);
  }

  /**
   * 상품 이름으로 조회.
   *
   * 상품 이름으로 상품 정보를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productControllerFindByName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productControllerFindByName(params: ProductControllerFindByName$Params, context?: HttpContext): Observable<ProductDto> {
    return this.productControllerFindByName$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDto>): ProductDto => r.body)
    );
  }

  /** Path part for operation `productControllerCreate()` */
  static readonly ProductControllerCreatePath = '/api/product';

  /**
   * 상품 등록.
   *
   * 새로운 상품을 등록합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  productControllerCreate$Response(params: ProductControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDto>> {
    return productControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 상품 등록.
   *
   * 새로운 상품을 등록합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  productControllerCreate(params: ProductControllerCreate$Params, context?: HttpContext): Observable<ProductDto> {
    return this.productControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDto>): ProductDto => r.body)
    );
  }

  /** Path part for operation `productControllerUpdateToViews()` */
  static readonly ProductControllerUpdateToViewsPath = '/api/product/{id}/views';

  /**
   * 상품 조회수 증가.
   *
   * 입력한 ID에 해당하는 상품의 조회수를 증가합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `productControllerUpdateToViews()` instead.
   *
   * This method doesn't expect any request body.
   */
  productControllerUpdateToViews$Response(params: ProductControllerUpdateToViews$Params, context?: HttpContext): Observable<StrictHttpResponse<ProductDto>> {
    return productControllerUpdateToViews(this.http, this.rootUrl, params, context);
  }

  /**
   * 상품 조회수 증가.
   *
   * 입력한 ID에 해당하는 상품의 조회수를 증가합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `productControllerUpdateToViews$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  productControllerUpdateToViews(params: ProductControllerUpdateToViews$Params, context?: HttpContext): Observable<ProductDto> {
    return this.productControllerUpdateToViews$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProductDto>): ProductDto => r.body)
    );
  }

}
