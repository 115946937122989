<main class="w-screen px-4 max-w-80">
    <div class="flex flex-col items-center w-full gap-4 p-5 bg-white rounded-xl">
        <h1 class="text-2xl font-extrabold text-gray-800">{{title}}</h1>
        @if(icons){
            <img [src]="icons" alt="">
        } 
         <div class="flex flex-col items-center text-sm font-medium text-gray-500">
            @for(item of content; track $index){
                <p>{{item}}</p>
            }
         </div>
         <app-button (click)="dismiss()" size="sm" expand="true" color="primary">확인</app-button>
    </div>
</main>