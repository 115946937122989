@if (coupon(); as coupon) {
<div class="flex overflow-hidden border rounded-xl border-neutral-200 size-full">
  <div class="flex flex-col flex-1 gap-4 p-4">
    <div class="flex flex-col gap-2">
      <div class="flex items-center gap-2">
        <app-icon name="solar:file-corrupted-bold-duotone" class="w-4 h-4 text-gray-400"></app-icon>
        <p class="font-medium text-gray-800">{{ coupon.coupon.name }}</p>
      </div>
      @switch (coupon.coupon.type) { @case ('FIXED') {
      <p class="font-bold text-secondary">
        <span class="text-2xl">{{ $any(coupon.coupon.detail).amount | number }}</span
        >원
      </p>
      } @case ('RATIONAL') {
      <div class="flex gap-1">
        <p class="font-bold text-secondary">
          <span class="text-2xl">{{ $any(coupon.coupon.detail).ratio }}</span
          >% 할인
        </p>
        <p class="font-bold text-secondary">
          최대 <span class="text-2xl">{{ $any(coupon.coupon.detail).max }}</span
          >원
        </p>
      </div>
      } @case ('REDEEM') {
      <p class="font-bold text-secondary">
        <span class="text-2xl">{{ $any(coupon.coupon.detail).product }}</span> 증정
      </p>
      } }
      <hr />
      @if(coupon.used){
      <p class="text-sm font-medium text-gray-500">사용 완료 : {{ coupon.usedAt | date : 'y. MM. dd. HH:mm' }}</p>
      } @else {
      <p class="text-sm font-medium text-gray-500">
        @if(coupon.coupon.noEnded){ 무제한 } @else {
        {{ coupon.createdAt | date : 'YYYY. MM. dd' }} ~ {{ getEndedDate(coupon.createdAt) }}
        }
      </p>
      }
    </div>
  </div>

  <!-- <p class="py-2 text-center bg-neutral-100 text-neutral-400">사용 완료 : {{ coupon.usedAt | date : 'y-MM-dd HH:mm:ss' }}</p> -->

  <button
    (click)="use.emit()"
    class="px-4"
    [ngClass]="coupon.used || coupon.isEnded ? 'bg-gray-400' : 'bg-secondary'"
    [disabled]="coupon.used || coupon.isEnded"
  >
    <div class="flex flex-col items-center justify-center font-semibold text-center text-white">
      @if(coupon.isEnded){
      <p>기간</p>
      <p>만료</p>
      } @else {
      <p>사용</p>
      <p>{{ coupon.used ? '완료' : '하기' }}</p>
      }
    </div>
  </button>
</div>
}
