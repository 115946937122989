import { Component, input, numberAttribute } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../common/base-input.component';

@Component({
  selector: 'app-input-textarea',
  imports: [FormsModule],
  styleUrl: '../common/input.common.scss',
  templateUrl: './input-textarea.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputTextareaComponent,
      multi: true,
    },
  ],
})
export class InputTextareaComponent extends BaseInputComponent<string> {
  rows = input<number, string | number>(4, { transform: numberAttribute });
  maxlength = input<number | null>(null);
  hint = input<string>();
}
