import { Component, input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../common/base-input.component';

@Component({
    selector: 'app-input-number',
    imports: [FormsModule],
    styleUrl: '../common/input.common.scss',
    template: `
    <label>
      @if (label(); as label) {
      <span>{{ label }}</span>
      }
      <div class="input-wrapper">
        <input
          class="flex-1"
          type="number"
          [(ngModel)]="value"
          [placeholder]="placeholder()"
          [spellcheck]="spellcheck()"
          [autocomplete]="autocomplete()"
          [autofocus]="autofocus()"
        />
        @if (suffix()) {
        <span>{{ suffix() }}</span>
        }
      </div>
    </label>
  `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputNumberComponent,
            multi: true,
        },
    ]
})
export class InputNumberComponent extends BaseInputComponent<string> {
  suffix = input<string | undefined>(undefined);
}
