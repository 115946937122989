import { Component, ElementRef, input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-select-option',
    imports: [CommonModule],
    template: `<ng-content />`
})
export class SelectOptionComponent {
  value = input.required<any>();

  constructor(public elementRef: ElementRef<HTMLElement>) {}
}
