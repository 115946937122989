import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

@Pipe({
  name: 'koreaDate',
  standalone: true,
})
export class KoreaDatePipe implements PipeTransform {
  transform(value: any) {
    dayjs.locale('ko');

    const result = dayjs(value).format('YYYY. MM. DD (ddd) HH:mm');
    if (!result) return null;
    return result;
  }
}
