import { AfterViewInit, booleanAttribute, Component, computed, contentChildren, ElementRef, input, signal, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseInputComponent } from '../common/base-input.component';
import { NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { SelectOptionComponent } from '../select-option/select-option.component';
import { IconComponent } from '../icon/icon.component';
import { CdkMenuModule } from '@angular/cdk/menu';

@Component({
    selector: 'app-select-v2',
    imports: [CommonModule, CdkMenuModule, IconComponent],
    templateUrl: './select-v2.component.html',
    styleUrl: '../common/input.common.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: SelectComponentV2,
            multi: true,
        },
    ]
})
export class SelectComponentV2 extends BaseInputComponent<any> implements AfterViewInit {
  multiple = input<boolean, string | boolean>(false, {
    transform: booleanAttribute,
  });

  selectInputRef = viewChild<ElementRef<HTMLSelectElement>>('selectInputRef');

  options = contentChildren(SelectOptionComponent);

  displayedValue = computed(() => {
    if (!this.value()) {
      return '';
    }

    if (this.multiple()) {
      const selectedValues = this.value() as string[];
      return selectedValues.map((v) => {
        const selectedOption = this.options().find((o) => o.value() === v);
        return selectedOption?.elementRef.nativeElement.innerText;
      });
    } else {
      const selectedOption = this.options().find((o) => o.value() === this.value());
      return selectedOption?.elementRef.nativeElement.innerText;
    }
  });

  open = signal<boolean>(false);

  ngAfterViewInit(): void {
    const selectInput = this.selectInputRef()?.nativeElement;
    if (selectInput) {
      fromEvent(selectInput, 'input').subscribe((ev) => {
        const selectedOption = this.options().find((o) => o.elementRef.nativeElement.innerText === (ev.target as HTMLSelectElement).value);
        if (selectedOption) {
          this.setValue(selectedOption.value());
          this.onChange(this.value());
          this.onTouched();
        }
      });
    }
  }

  toggle() {
    this.open.set(!this.open());
  }

  included(value: string | number) {
    if (!this.value()) return false;
    return (this.value() as string[]).includes(value.toString());
  }

  setValue(value: string | number) {
    if (this.multiple() && typeof value === 'string') {
      if (!this.value()) this.value.set([]);
      const selectedValues = this.value() as string[];
      if (selectedValues.includes(value)) {
        this.value.set(selectedValues.filter((v) => v !== value));
      } else {
        this.value.set([...selectedValues, value]);
      }
    } else {
      this.value.set(value);
      this.open.set(false);
      this.onChange(value);
      this.onTouched();
    }
  }
}
