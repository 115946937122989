import { Component, signal } from '@angular/core';
import { BaseInputComponent } from '../common/base-input.component';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-input-password',
  imports: [FormsModule, IconComponent],
  styleUrl: '../common/input.common.scss',
  template: `
    <label>
      @if (label(); as label) {
      <span>{{ label }}</span>
      <div class="flex gap-1 items-center input-wrapper relative">
        <input
          class="flex-1"
          [type]="!showPassword() ? 'password' : 'text'"
          [(ngModel)]="value"
          [placeholder]="placeholder()"
          [spellcheck]="spellcheck()"
          [autofocus]="autofocus()"
          autocomplete="current-password"
        />
        <div class="absolute right-4 bottom-2 w-5 h-5 mb-1">
          <app-icon
            [name]="!showPassword() ? 'mdi:eye' : 'mdi:eye-off'"
            class="cursor-pointer mr-2 w-5 h-5"
            (click)="showPassword.set(!showPassword())"
          />
        </div>
      </div>
      }
    </label>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputPasswordComponent,
      multi: true,
    },
  ],
})
export class InputPasswordComponent extends BaseInputComponent<string> {
  showPassword = signal<boolean>(false);
}
