/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { faqControllerCreate } from '../fn/faq/faq-controller-create';
import { FaqControllerCreate$Params } from '../fn/faq/faq-controller-create';
import { faqControllerDelete } from '../fn/faq/faq-controller-delete';
import { FaqControllerDelete$Params } from '../fn/faq/faq-controller-delete';
import { faqControllerFindAll } from '../fn/faq/faq-controller-find-all';
import { FaqControllerFindAll$Params } from '../fn/faq/faq-controller-find-all';
import { faqControllerReorder } from '../fn/faq/faq-controller-reorder';
import { FaqControllerReorder$Params } from '../fn/faq/faq-controller-reorder';
import { faqControllerUpdate } from '../fn/faq/faq-controller-update';
import { FaqControllerUpdate$Params } from '../fn/faq/faq-controller-update';
import { FaqDto } from '../models/faq-dto';

@Injectable({ providedIn: 'root' })
export class FaqService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `faqControllerFindAll()` */
  static readonly FaqControllerFindAllPath = '/api/faq';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faqControllerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqControllerFindAll$Response(params?: FaqControllerFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FaqDto>>> {
    return faqControllerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faqControllerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqControllerFindAll(params?: FaqControllerFindAll$Params, context?: HttpContext): Observable<Array<FaqDto>> {
    return this.faqControllerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FaqDto>>): Array<FaqDto> => r.body)
    );
  }

  /** Path part for operation `faqControllerCreate()` */
  static readonly FaqControllerCreatePath = '/api/faq';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faqControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faqControllerCreate$Response(params: FaqControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqDto>> {
    return faqControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faqControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faqControllerCreate(params: FaqControllerCreate$Params, context?: HttpContext): Observable<FaqDto> {
    return this.faqControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqDto>): FaqDto => r.body)
    );
  }

  /** Path part for operation `faqControllerReorder()` */
  static readonly FaqControllerReorderPath = '/api/faq/reorder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faqControllerReorder()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqControllerReorder$Response(params: FaqControllerReorder$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FaqDto>>> {
    return faqControllerReorder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faqControllerReorder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqControllerReorder(params: FaqControllerReorder$Params, context?: HttpContext): Observable<Array<FaqDto>> {
    return this.faqControllerReorder$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FaqDto>>): Array<FaqDto> => r.body)
    );
  }

  /** Path part for operation `faqControllerDelete()` */
  static readonly FaqControllerDeletePath = '/api/faq/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faqControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqControllerDelete$Response(params: FaqControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqDto>> {
    return faqControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faqControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faqControllerDelete(params: FaqControllerDelete$Params, context?: HttpContext): Observable<FaqDto> {
    return this.faqControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqDto>): FaqDto => r.body)
    );
  }

  /** Path part for operation `faqControllerUpdate()` */
  static readonly FaqControllerUpdatePath = '/api/faq/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faqControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faqControllerUpdate$Response(params: FaqControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<FaqDto>> {
    return faqControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faqControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faqControllerUpdate(params: FaqControllerUpdate$Params, context?: HttpContext): Observable<FaqDto> {
    return this.faqControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaqDto>): FaqDto => r.body)
    );
  }

}
