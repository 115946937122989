<label class="relative">
    @if (label(); as label) {
    <span>{{ label }}</span>
    }
    <div class="input-wrapper">
      <textarea
        class="w-full resize-none"
        [rows]="rows()"
        [(ngModel)]="value"
        [placeholder]="placeholder()"
        [spellcheck]="spellcheck()"
        [autocomplete]="autocomplete()"
        [maxlength]="maxlength()"
      ></textarea>
    </div>
    <div class="flex justify-between mt-0.5">
      <p class="w-full text-sm text-gray-500 whitespace-nowrap">
        @if(hint()){
          {{hint()}}
        }
      </p>
      @if (maxlength()) {
          <div class="flex justify-end w-full text-sm text-gray-500">
            {{ value() ? value()?.length : 0 }} / {{ maxlength() }}
          </div>
          }
    </div>
  </label>