import { booleanAttribute, Component, ElementRef, inject, input, signal, viewChild } from '@angular/core';
import { AbstractControl, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { MemberService } from '@api-client';
import { josa } from 'es-hangul';
import { ToastService } from '../../services';
import { ButtonComponent } from '../button/button.component';
import { BaseInputComponent } from '../common/base-input.component';
import { InputNumberComponent } from '../input-number/input-number.component';
import { InputTextComponent } from '../input-text/input-text.component';

@Component({
  selector: 'app-input-tel',
  imports: [ButtonComponent, FormsModule, InputNumberComponent],
  templateUrl: './input-tel.component.html',
  styleUrl: '../common/input.common.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputTelComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: InputTelComponent,
      multi: true,
    },
  ],
})
export class InputTelComponent extends BaseInputComponent<string> implements Validator {
  inputRef = viewChild.required<ElementRef<HTMLInputElement>>('inputRef');

  __validate = input<boolean, string | boolean>(false, { transform: booleanAttribute, alias: 'validate' });

  service = inject(MemberService);
  toast = inject(ToastService);
  token = signal<string | undefined>(undefined);
  code = signal<string | undefined>(undefined);
  verified = signal<boolean>(false);

  control: AbstractControl | null = null;

  validate(control: AbstractControl): ValidationErrors | null {
    this.control = control;
    if (!this.__validate()) return null;

    return this.verified() ? null : { unverified: true };
  }

  _validate(control: AbstractControl): ValidationErrors | null {
    this.control = control;
    if (!this.__validate()) return null;

    return this.verified() ? null : { unverified: true };
  }

  requestCode() {
    const tel = this.value();
    if (!tel) {
      this.toast.warning(`${josa(this.label() || '전화번호', '을/를')} 입력해주세요.`);
      return;
    }

    this.service.memberControllerRequestAuthCode({ tel }).subscribe({
      next: ({ token }) => {
        this.token.set(token);
        this.toast.success('인증번호가 발송되었습니다.');
      },
    });
  }

  verifyCode() {
    const code = this.code();
    const token = this.token();
    if (!code || !token) {
      this.toast.warning('인증번호를 입력해주세요.');
      return;
    }

    this.service.memberControllerVerifyAuthCode({ body: { code, token } }).subscribe({
      next: () => {
        this.verified.set(true);
        this.control?.setErrors(null);
        this.toast.success('인증되었습니다.');
        this.validate(this.control!);
      },
    });
  }

  reset() {
    this.value.set('');
    this.verified.set(false);
    this.code.set(undefined);
    this.token.set(undefined);
    this.inputRef().nativeElement.focus();
  }
}
