/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { paymentControllerFindById } from '../fn/payment/payment-controller-find-by-id';
import { PaymentControllerFindById$Params } from '../fn/payment/payment-controller-find-by-id';
import { paymentControllerFindByMemberId } from '../fn/payment/payment-controller-find-by-member-id';
import { PaymentControllerFindByMemberId$Params } from '../fn/payment/payment-controller-find-by-member-id';
import { PaymentDto } from '../models/payment-dto';

@Injectable({ providedIn: 'root' })
export class PaymentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `paymentControllerFindById()` */
  static readonly PaymentControllerFindByIdPath = '/api/payment/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentControllerFindById$Response(params: PaymentControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<PaymentDto>> {
    return paymentControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentControllerFindById(params: PaymentControllerFindById$Params, context?: HttpContext): Observable<PaymentDto> {
    return this.paymentControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentDto>): PaymentDto => r.body)
    );
  }

  /** Path part for operation `paymentControllerFindByMemberId()` */
  static readonly PaymentControllerFindByMemberIdPath = '/api/payment/member/{memberId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentControllerFindByMemberId()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentControllerFindByMemberId$Response(params: PaymentControllerFindByMemberId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PaymentDto>>> {
    return paymentControllerFindByMemberId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentControllerFindByMemberId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentControllerFindByMemberId(params: PaymentControllerFindByMemberId$Params, context?: HttpContext): Observable<Array<PaymentDto>> {
    return this.paymentControllerFindByMemberId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentDto>>): Array<PaymentDto> => r.body)
    );
  }

}
