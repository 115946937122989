import { Component } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputComponent } from '../common/base-input.component';

@Component({
    selector: 'app-input-email',
    imports: [FormsModule],
    styleUrl: '../common/input.common.scss',
    template: `
    <label>
      @if (label(); as label) {
      <span>{{ label }}</span>
      }
      <div class="input-wrapper">
        <input
          class="w-full"
          type="email"
          inputmode="email"
          [(ngModel)]="value"
          [placeholder]="placeholder()"
          [spellcheck]="spellcheck()"
          [autocomplete]="autocomplete()"
        />
      </div>
    </label>
  `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputEmailComponent,
            multi: true,
        },
    ]
})
export class InputEmailComponent extends BaseInputComponent<string> {}
