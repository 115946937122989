import { Component, input, model, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqDto } from '@api-client';

@Component({
    selector: 'app-faq-item',
    imports: [CommonModule],
    template: `
    @if (faq(); as faq) {
    <div [ngClass]="{ 'bg-neutral-100 rounded-md ': open() }" class="flex flex-col px-4 py-5 gap-2">
      <section class="flex gap-2 items-start">
        <span class="text-xl font-extrabold text-primary">Q</span>
        <p class="mt-0.5 font-semibold">{{ faq.question }}</p>
      </section>

      @if (open()) {
      <section class="flex gap-2 items-start">
        <span class="text-xl font-extrabold text-neutral-500">A</span>
        <p class="max-w-full break-words mt-0.5">{{ faq.answer }}</p>
      </section>
      }
    </div>
    }
  `,
    styles: `
    :host {
      @apply flex flex-col gap-3;
    }
  `
})
export class FaqItemComponent {
  faq = input.required<FaqDto>();

  open = model<boolean>(false);
}
