/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CountStatDto } from '../../models/count-stat-dto';

export interface StatControllerGetCountStat$Params {
  today: string;
  model: 'Admin' | 'Member' | 'OAuth' | 'Address' | 'Attraction' | 'AttractionReview' | 'FavoriteAttraction' | 'Reservation' | 'ReservationStats' | 'VisitorStats' | 'Product' | 'Cart' | 'Order' | 'OrderItem' | 'CoBuying' | 'CobuyingCategory' | 'CoBuyingParticipant' | 'Payment' | 'Coupon' | 'CouponLog' | 'PointLog' | 'Inquiry' | 'Faq' | 'File' | 'Notification' | 'Log' | 'Setting' | 'Version';
}

export function statControllerGetCountStat(http: HttpClient, rootUrl: string, params: StatControllerGetCountStat$Params, context?: HttpContext): Observable<StrictHttpResponse<CountStatDto>> {
  const rb = new RequestBuilder(rootUrl, statControllerGetCountStat.PATH, 'get');
  if (params) {
    rb.query('today', params.today, {});
    rb.path('model', params.model, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CountStatDto>;
    })
  );
}

statControllerGetCountStat.PATH = '/api/stat/count/{model}';
