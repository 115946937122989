/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminControllerBlock } from '../fn/admin/admin-controller-block';
import { AdminControllerBlock$Params } from '../fn/admin/admin-controller-block';
import { adminControllerCreate } from '../fn/admin/admin-controller-create';
import { AdminControllerCreate$Params } from '../fn/admin/admin-controller-create';
import { adminControllerFindByCode } from '../fn/admin/admin-controller-find-by-code';
import { AdminControllerFindByCode$Params } from '../fn/admin/admin-controller-find-by-code';
import { adminControllerFindById } from '../fn/admin/admin-controller-find-by-id';
import { AdminControllerFindById$Params } from '../fn/admin/admin-controller-find-by-id';
import { adminControllerFindByUsername } from '../fn/admin/admin-controller-find-by-username';
import { AdminControllerFindByUsername$Params } from '../fn/admin/admin-controller-find-by-username';
import { adminControllerGetAll } from '../fn/admin/admin-controller-get-all';
import { AdminControllerGetAll$Params } from '../fn/admin/admin-controller-get-all';
import { adminControllerGetMe } from '../fn/admin/admin-controller-get-me';
import { AdminControllerGetMe$Params } from '../fn/admin/admin-controller-get-me';
import { adminControllerLogin } from '../fn/admin/admin-controller-login';
import { AdminControllerLogin$Params } from '../fn/admin/admin-controller-login';
import { adminControllerLogout } from '../fn/admin/admin-controller-logout';
import { AdminControllerLogout$Params } from '../fn/admin/admin-controller-logout';
import { adminControllerResetPassword } from '../fn/admin/admin-controller-reset-password';
import { AdminControllerResetPassword$Params } from '../fn/admin/admin-controller-reset-password';
import { adminControllerSearchOffset } from '../fn/admin/admin-controller-search-offset';
import { AdminControllerSearchOffset$Params } from '../fn/admin/admin-controller-search-offset';
import { adminControllerUnblock } from '../fn/admin/admin-controller-unblock';
import { AdminControllerUnblock$Params } from '../fn/admin/admin-controller-unblock';
import { adminControllerUpdate } from '../fn/admin/admin-controller-update';
import { AdminControllerUpdate$Params } from '../fn/admin/admin-controller-update';
import { AdminDto } from '../models/admin-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { ResetPasswordResponseDto } from '../models/reset-password-response-dto';
import { TokensDto } from '../models/tokens-dto';

@Injectable({ providedIn: 'root' })
export class AdminService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminControllerGetMe()` */
  static readonly AdminControllerGetMePath = '/api/admin/me';

  /**
   * 내 정보 조회.
   *
   * 내 정보를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerGetMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerGetMe$Response(params?: AdminControllerGetMe$Params, context?: HttpContext): Observable<StrictHttpResponse<AdminDto>> {
    return adminControllerGetMe(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 정보 조회.
   *
   * 내 정보를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerGetMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerGetMe(params?: AdminControllerGetMe$Params, context?: HttpContext): Observable<AdminDto> {
    return this.adminControllerGetMe$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminDto>): AdminDto => r.body)
    );
  }

  /** Path part for operation `adminControllerSearchOffset()` */
  static readonly AdminControllerSearchOffsetPath = '/api/admin/search/offset';

  /**
   * 관리자 검색.
   *
   * 관리자를 검색합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerSearchOffset$Response(params: AdminControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<AdminDto>;
}>> {
    return adminControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 관리자 검색.
   *
   * 관리자를 검색합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerSearchOffset(params: AdminControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto & {
'items'?: Array<AdminDto>;
}> {
    return this.adminControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<AdminDto>;
}>): OffsetPaginationDto & {
'items'?: Array<AdminDto>;
} => r.body)
    );
  }

  /** Path part for operation `adminControllerGetAll()` */
  static readonly AdminControllerGetAllPath = '/api/admin/all';

  /**
   * 모든 관리자 조회.
   *
   * (관리자) 모든 관리자를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerGetAll$Response(params?: AdminControllerGetAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AdminDto>>> {
    return adminControllerGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 모든 관리자 조회.
   *
   * (관리자) 모든 관리자를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerGetAll(params?: AdminControllerGetAll$Params, context?: HttpContext): Observable<Array<AdminDto>> {
    return this.adminControllerGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AdminDto>>): Array<AdminDto> => r.body)
    );
  }

  /** Path part for operation `adminControllerFindById()` */
  static readonly AdminControllerFindByIdPath = '/api/admin/{id}';

  /**
   * 관리자 조회.
   *
   * 관리자를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerFindById$Response(params: AdminControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<AdminDto>> {
    return adminControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 관리자 조회.
   *
   * 관리자를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerFindById(params: AdminControllerFindById$Params, context?: HttpContext): Observable<AdminDto> {
    return this.adminControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminDto>): AdminDto => r.body)
    );
  }

  /** Path part for operation `adminControllerUpdate()` */
  static readonly AdminControllerUpdatePath = '/api/admin/{id}';

  /**
   * 관리자 수정.
   *
   * 관리자를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminControllerUpdate$Response(params: AdminControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<AdminDto>> {
    return adminControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 관리자 수정.
   *
   * 관리자를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminControllerUpdate(params: AdminControllerUpdate$Params, context?: HttpContext): Observable<AdminDto> {
    return this.adminControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminDto>): AdminDto => r.body)
    );
  }

  /** Path part for operation `adminControllerFindByUsername()` */
  static readonly AdminControllerFindByUsernamePath = '/api/admin/username/{username}';

  /**
   * 관리자 아이디 조회.
   *
   * 관리자 아이디로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerFindByUsername()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerFindByUsername$Response(params: AdminControllerFindByUsername$Params, context?: HttpContext): Observable<StrictHttpResponse<AdminDto>> {
    return adminControllerFindByUsername(this.http, this.rootUrl, params, context);
  }

  /**
   * 관리자 아이디 조회.
   *
   * 관리자 아이디로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerFindByUsername$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerFindByUsername(params: AdminControllerFindByUsername$Params, context?: HttpContext): Observable<AdminDto> {
    return this.adminControllerFindByUsername$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminDto>): AdminDto => r.body)
    );
  }

  /** Path part for operation `adminControllerFindByCode()` */
  static readonly AdminControllerFindByCodePath = '/api/admin/code/{code}';

  /**
   * 관리자 코드 조회.
   *
   * 관리자 코드로 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerFindByCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerFindByCode$Response(params: AdminControllerFindByCode$Params, context?: HttpContext): Observable<StrictHttpResponse<AdminDto>> {
    return adminControllerFindByCode(this.http, this.rootUrl, params, context);
  }

  /**
   * 관리자 코드 조회.
   *
   * 관리자 코드로 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerFindByCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerFindByCode(params: AdminControllerFindByCode$Params, context?: HttpContext): Observable<AdminDto> {
    return this.adminControllerFindByCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminDto>): AdminDto => r.body)
    );
  }

  /** Path part for operation `adminControllerCreate()` */
  static readonly AdminControllerCreatePath = '/api/admin';

  /**
   * 관리자 생성.
   *
   * 관리자를 생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminControllerCreate$Response(params: AdminControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<AdminDto>> {
    return adminControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 관리자 생성.
   *
   * 관리자를 생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminControllerCreate(params: AdminControllerCreate$Params, context?: HttpContext): Observable<AdminDto> {
    return this.adminControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AdminDto>): AdminDto => r.body)
    );
  }

  /** Path part for operation `adminControllerLogin()` */
  static readonly AdminControllerLoginPath = '/api/admin/login';

  /**
   * 관리자 로그인.
   *
   * 관리자 로그인을 합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminControllerLogin$Response(params: AdminControllerLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<TokensDto>> {
    return adminControllerLogin(this.http, this.rootUrl, params, context);
  }

  /**
   * 관리자 로그인.
   *
   * 관리자 로그인을 합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminControllerLogin(params: AdminControllerLogin$Params, context?: HttpContext): Observable<TokensDto> {
    return this.adminControllerLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<TokensDto>): TokensDto => r.body)
    );
  }

  /** Path part for operation `adminControllerLogout()` */
  static readonly AdminControllerLogoutPath = '/api/admin/logout';

  /**
   * 관리자 로그아웃.
   *
   * 관리자 로그아웃을 합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerLogout$Response(params?: AdminControllerLogout$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminControllerLogout(this.http, this.rootUrl, params, context);
  }

  /**
   * 관리자 로그아웃.
   *
   * 관리자 로그아웃을 합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerLogout(params?: AdminControllerLogout$Params, context?: HttpContext): Observable<void> {
    return this.adminControllerLogout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminControllerResetPassword()` */
  static readonly AdminControllerResetPasswordPath = '/api/admin/{id}/reset-password';

  /**
   * 비밀번호 재생성.
   *
   * 비밀번호를 재생성합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerResetPassword()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerResetPassword$Response(params: AdminControllerResetPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<ResetPasswordResponseDto>> {
    return adminControllerResetPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * 비밀번호 재생성.
   *
   * 비밀번호를 재생성합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerResetPassword$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerResetPassword(params: AdminControllerResetPassword$Params, context?: HttpContext): Observable<ResetPasswordResponseDto> {
    return this.adminControllerResetPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResetPasswordResponseDto>): ResetPasswordResponseDto => r.body)
    );
  }

  /** Path part for operation `adminControllerBlock()` */
  static readonly AdminControllerBlockPath = '/api/admin/{id}/block';

  /**
   * 관리자 차단.
   *
   * 관리자를 차단합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerBlock()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerBlock$Response(params: AdminControllerBlock$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminControllerBlock(this.http, this.rootUrl, params, context);
  }

  /**
   * 관리자 차단.
   *
   * 관리자를 차단합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerBlock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerBlock(params: AdminControllerBlock$Params, context?: HttpContext): Observable<void> {
    return this.adminControllerBlock$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminControllerUnblock()` */
  static readonly AdminControllerUnblockPath = '/api/admin/{id}/unblock';

  /**
   * 관리자 차단 해제.
   *
   * 관리자의 차단을 해제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminControllerUnblock()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerUnblock$Response(params: AdminControllerUnblock$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminControllerUnblock(this.http, this.rootUrl, params, context);
  }

  /**
   * 관리자 차단 해제.
   *
   * 관리자의 차단을 해제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminControllerUnblock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminControllerUnblock(params: AdminControllerUnblock$Params, context?: HttpContext): Observable<void> {
    return this.adminControllerUnblock$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
