import {
  booleanAttribute,
  Component,
  computed,
  contentChildren,
  inject,
  input,
  model,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  signal,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, takeWhile } from 'rxjs';
import { NaverMapMarkerComponent } from './naver-map-marker/naver-map-marker.component';

@Component({
  selector: 'app-naver-map',
  standalone: true,
  templateUrl: './naver-map.component.html',
  styleUrl: './naver-map.component.scss',
})
export class NaverMapComponent implements OnInit {
  renderer = inject(Renderer2);
  platformId = inject(PLATFORM_ID);
  modalMap: any;

  markers = contentChildren(NaverMapMarkerComponent);
  markers$ = toObservable(this.markers);

  draggable = input<boolean, string | boolean>(true, { transform: booleanAttribute });
  zoomable = input<boolean, string | boolean>(true, { transform: booleanAttribute });

  lat = model<number>();
  lng = model<number>();
  coord = computed(() => ({ lat: this.lat(), lng: this.lng() }));
  coord$ = toObservable(this.coord);

  _markers = signal<naver.maps.Marker[]>([]);

  map: naver.maps.Map | null = null;
  zoom = signal<number>(17);

  constructor() {
    this.markers$.pipe(takeWhile(() => this.map !== null)).subscribe((markers) => {
      // this.setMarkers();
      markers.forEach((marker) => {
        marker.changed.subscribe(() => {
          this.setMarkers();
        });
      });
    });

    this.coord$
      .pipe(
        takeWhile(() => !!this.map),
        filter(({ lat, lng }) => !!lat && !!lng)
      )
      .subscribe(({ lat, lng }) => {
        this.map!.panTo(new naver.maps.LatLng(lat!, lng!), { duration: 300 });
      });

    toObservable(this.zoom).subscribe((zoom) => {
      if (this.map && zoom) {
        this.map.setZoom(zoom);
      }
    });
  }

  ngOnInit(): void {
    const lat = this.lat() || 35.00321;
    const lng = this.lng() || 128.06463;

    const center = new naver.maps.LatLng(lat, lng);
    this.map = new naver.maps.Map('map', {
      center,
      zoom: this.zoom(),
      draggable: this.draggable(),
      pinchZoom: this.zoomable(),
      scrollWheel: this.zoomable(),
      disableDoubleTapZoom: !this.zoomable(),
      disableDoubleClickZoom: !this.zoomable(),
      disableTwoFingerTapZoom: !this.zoomable(),
    });
  }

  setMarkers() {
    this._markers().forEach((marker) => {
      marker.setMap(null);
    });

    this._markers.set([]);

    this.markers().forEach((marker) => {
      const m = new naver.maps.Marker({
        position: new naver.maps.LatLng(marker.lat()!, marker.lng()!),
        map: this.map!,
      });

      this._markers().push(m);
    });
  }

  setZoom(zoom: number) {
    this.zoom.set(zoom);
  }
}
