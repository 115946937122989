/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { notificationControllerClearAll } from '../fn/notification/notification-controller-clear-all';
import { NotificationControllerClearAll$Params } from '../fn/notification/notification-controller-clear-all';
import { notificationControllerDelete } from '../fn/notification/notification-controller-delete';
import { NotificationControllerDelete$Params } from '../fn/notification/notification-controller-delete';
import { notificationControllerGetMyNotifications } from '../fn/notification/notification-controller-get-my-notifications';
import { NotificationControllerGetMyNotifications$Params } from '../fn/notification/notification-controller-get-my-notifications';
import { notificationControllerSetRead } from '../fn/notification/notification-controller-set-read';
import { NotificationControllerSetRead$Params } from '../fn/notification/notification-controller-set-read';
import { notificationControllerSetReadAll } from '../fn/notification/notification-controller-set-read-all';
import { NotificationControllerSetReadAll$Params } from '../fn/notification/notification-controller-set-read-all';
import { NotificationDto } from '../models/notification-dto';

@Injectable({ providedIn: 'root' })
export class NotificationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `notificationControllerGetMyNotifications()` */
  static readonly NotificationControllerGetMyNotificationsPath = '/api/notification/me';

  /**
   * 내 알림 조회.
   *
   * 내 알림을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationControllerGetMyNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationControllerGetMyNotifications$Response(params?: NotificationControllerGetMyNotifications$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NotificationDto>>> {
    return notificationControllerGetMyNotifications(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 알림 조회.
   *
   * 내 알림을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationControllerGetMyNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationControllerGetMyNotifications(params?: NotificationControllerGetMyNotifications$Params, context?: HttpContext): Observable<Array<NotificationDto>> {
    return this.notificationControllerGetMyNotifications$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NotificationDto>>): Array<NotificationDto> => r.body)
    );
  }

  /** Path part for operation `notificationControllerSetRead()` */
  static readonly NotificationControllerSetReadPath = '/api/notification/{id}/read';

  /**
   * 알림 읽음 처리.
   *
   * 알림을 읽음 처리합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationControllerSetRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationControllerSetRead$Response(params: NotificationControllerSetRead$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return notificationControllerSetRead(this.http, this.rootUrl, params, context);
  }

  /**
   * 알림 읽음 처리.
   *
   * 알림을 읽음 처리합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationControllerSetRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationControllerSetRead(params: NotificationControllerSetRead$Params, context?: HttpContext): Observable<void> {
    return this.notificationControllerSetRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationControllerSetReadAll()` */
  static readonly NotificationControllerSetReadAllPath = '/api/notification/read-all';

  /**
   * 모든 알림 읽음 처리.
   *
   * 모든 알림을 읽음 처리합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationControllerSetReadAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationControllerSetReadAll$Response(params?: NotificationControllerSetReadAll$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return notificationControllerSetReadAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 모든 알림 읽음 처리.
   *
   * 모든 알림을 읽음 처리합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationControllerSetReadAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationControllerSetReadAll(params?: NotificationControllerSetReadAll$Params, context?: HttpContext): Observable<void> {
    return this.notificationControllerSetReadAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationControllerClearAll()` */
  static readonly NotificationControllerClearAllPath = '/api/notification/clear';

  /**
   * 모든 알림 삭제.
   *
   * 모든 알림을 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationControllerClearAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationControllerClearAll$Response(params?: NotificationControllerClearAll$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return notificationControllerClearAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 모든 알림 삭제.
   *
   * 모든 알림을 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationControllerClearAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationControllerClearAll(params?: NotificationControllerClearAll$Params, context?: HttpContext): Observable<void> {
    return this.notificationControllerClearAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationControllerDelete()` */
  static readonly NotificationControllerDeletePath = '/api/notification/{id}';

  /**
   * 알림 삭제.
   *
   * 알림을 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationControllerDelete$Response(params: NotificationControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return notificationControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 알림 삭제.
   *
   * 알림을 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationControllerDelete(params: NotificationControllerDelete$Params, context?: HttpContext): Observable<void> {
    return this.notificationControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
