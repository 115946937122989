@defer (when options()) {
<div>
  <div class="flex mb-1">
    <label >{{ label() }}</label>
    @if(required()){
      <span class="ml-0.5 pb-0.5 text-primary">*</span>
    }
  </div>
  <!-- <select #selectInputRef class="p-3 text-sm rounded-md bg-neutral-100 md:hidden">
    @for (option of options(); track $index) {
    <option [value]="option" class="text-sm">
      {{ option.elementRef.nativeElement.textContent }}
    </option>
    }
  </select> -->

  <div
    #menuContainer
    class="flex items-center justify-between w-full px-4 py-[10px] text-sm transition-colors border border-gray-300 rounded-lg select-none validation-indicator focus:border-primary"
    [cdkMenuTriggerFor]="optionsMenu"
    [ngClass]="!disabled() ? 'cursor-pointer bg-white' : 'cursor-not-allowed bg-neutral-100'"
    (click)="disabled() ? null : toggle()"
  >
    @if (displayedValue(); as displayedValue) {
    <span class="font-bold">{{ displayedValue }}</span>
    } @else {
    <span class="font-semibold text-neutral-400">{{ label() }}를(을) 선택해 주세요.</span>
    }
    <app-icon name="mdi:chevron-down" class="transition-transform" [class.rotate-180]="open()" />
  </div>
</div>

<ng-template #optionsMenu>
  <div
    cdkMenu
    class="flex flex-col overflow-y-auto bg-white rounded-md shadow-xl"
    style="max-height: 220px"
    [ngStyle]="{ width: menuContainer.offsetWidth + 'px' }"
  >
    @for (option of options(); track $index) {
    <div
      cdkMenuItem
      (click)="setValue(option.value())"
      class="flex items-center justify-between gap-8 p-3 text-sm font-semibold text-gray-800 cursor-pointer hover:bg-neutral-200"
    >
      {{ option.elementRef.nativeElement.textContent }}
      @if (multiple() && included(option.value())) {
      <app-icon name="mdi:check" />
      }
    </div>
    }
  </div>
</ng-template>
}

<div class="hidden">
  <ng-content />
</div>
