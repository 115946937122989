/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { OffsetPaginationDto } from '../models/offset-pagination-dto';
import { versionControllerAddVersion } from '../fn/version/version-controller-add-version';
import { VersionControllerAddVersion$Params } from '../fn/version/version-controller-add-version';
import { versionControllerGetAll } from '../fn/version/version-controller-get-all';
import { VersionControllerGetAll$Params } from '../fn/version/version-controller-get-all';
import { versionControllerGetLatest } from '../fn/version/version-controller-get-latest';
import { VersionControllerGetLatest$Params } from '../fn/version/version-controller-get-latest';
import { versionControllerGetVersion } from '../fn/version/version-controller-get-version';
import { VersionControllerGetVersion$Params } from '../fn/version/version-controller-get-version';
import { versionControllerSearchOffset } from '../fn/version/version-controller-search-offset';
import { VersionControllerSearchOffset$Params } from '../fn/version/version-controller-search-offset';
import { VersionDto } from '../models/version-dto';

@Injectable({ providedIn: 'root' })
export class VersionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `versionControllerGetVersion()` */
  static readonly VersionControllerGetVersionPath = '/api/version/version/{version}';

  /**
   * 버전 정보 조회.
   *
   * 버전 정보를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `versionControllerGetVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionControllerGetVersion$Response(params: VersionControllerGetVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<VersionDto>> {
    return versionControllerGetVersion(this.http, this.rootUrl, params, context);
  }

  /**
   * 버전 정보 조회.
   *
   * 버전 정보를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `versionControllerGetVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionControllerGetVersion(params: VersionControllerGetVersion$Params, context?: HttpContext): Observable<VersionDto> {
    return this.versionControllerGetVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<VersionDto>): VersionDto => r.body)
    );
  }

  /** Path part for operation `versionControllerGetLatest()` */
  static readonly VersionControllerGetLatestPath = '/api/version/latest';

  /**
   * 최신 버전 정보 조회.
   *
   * 최신 버전 정보를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `versionControllerGetLatest()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionControllerGetLatest$Response(params?: VersionControllerGetLatest$Params, context?: HttpContext): Observable<StrictHttpResponse<VersionDto>> {
    return versionControllerGetLatest(this.http, this.rootUrl, params, context);
  }

  /**
   * 최신 버전 정보 조회.
   *
   * 최신 버전 정보를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `versionControllerGetLatest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionControllerGetLatest(params?: VersionControllerGetLatest$Params, context?: HttpContext): Observable<VersionDto> {
    return this.versionControllerGetLatest$Response(params, context).pipe(
      map((r: StrictHttpResponse<VersionDto>): VersionDto => r.body)
    );
  }

  /** Path part for operation `versionControllerGetAll()` */
  static readonly VersionControllerGetAllPath = '/api/version/all';

  /**
   * 모든 버전 조회.
   *
   * (관리자) 모든 버전을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `versionControllerGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionControllerGetAll$Response(params?: VersionControllerGetAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<VersionDto>>> {
    return versionControllerGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 모든 버전 조회.
   *
   * (관리자) 모든 버전을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `versionControllerGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionControllerGetAll(params?: VersionControllerGetAll$Params, context?: HttpContext): Observable<Array<VersionDto>> {
    return this.versionControllerGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<VersionDto>>): Array<VersionDto> => r.body)
    );
  }

  /** Path part for operation `versionControllerSearchOffset()` */
  static readonly VersionControllerSearchOffsetPath = '/api/version/search/offset';

  /**
   * 버전 정보 조회.
   *
   * 버전 정보를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `versionControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionControllerSearchOffset$Response(params: VersionControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<VersionDto>;
}>> {
    return versionControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 버전 정보 조회.
   *
   * 버전 정보를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `versionControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  versionControllerSearchOffset(params: VersionControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto & {
'items'?: Array<VersionDto>;
}> {
    return this.versionControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<VersionDto>;
}>): OffsetPaginationDto & {
'items'?: Array<VersionDto>;
} => r.body)
    );
  }

  /** Path part for operation `versionControllerAddVersion()` */
  static readonly VersionControllerAddVersionPath = '/api/version';

  /**
   * 새 버전 등록.
   *
   * 새 버전을 등록합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `versionControllerAddVersion()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  versionControllerAddVersion$Response(params: VersionControllerAddVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return versionControllerAddVersion(this.http, this.rootUrl, params, context);
  }

  /**
   * 새 버전 등록.
   *
   * 새 버전을 등록합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `versionControllerAddVersion$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  versionControllerAddVersion(params: VersionControllerAddVersion$Params, context?: HttpContext): Observable<void> {
    return this.versionControllerAddVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
