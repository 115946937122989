/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { coBuyingControllerCreate } from '../fn/co-buying/co-buying-controller-create';
import { CoBuyingControllerCreate$Params } from '../fn/co-buying/co-buying-controller-create';
import { coBuyingControllerDelete } from '../fn/co-buying/co-buying-controller-delete';
import { CoBuyingControllerDelete$Params } from '../fn/co-buying/co-buying-controller-delete';
import { coBuyingControllerFindById } from '../fn/co-buying/co-buying-controller-find-by-id';
import { CoBuyingControllerFindById$Params } from '../fn/co-buying/co-buying-controller-find-by-id';
import { coBuyingControllerFindByName } from '../fn/co-buying/co-buying-controller-find-by-name';
import { CoBuyingControllerFindByName$Params } from '../fn/co-buying/co-buying-controller-find-by-name';
import { coBuyingControllerGetAll } from '../fn/co-buying/co-buying-controller-get-all';
import { CoBuyingControllerGetAll$Params } from '../fn/co-buying/co-buying-controller-get-all';
import { coBuyingControllerSearchCursor } from '../fn/co-buying/co-buying-controller-search-cursor';
import { CoBuyingControllerSearchCursor$Params } from '../fn/co-buying/co-buying-controller-search-cursor';
import { coBuyingControllerSearchOffset } from '../fn/co-buying/co-buying-controller-search-offset';
import { CoBuyingControllerSearchOffset$Params } from '../fn/co-buying/co-buying-controller-search-offset';
import { coBuyingControllerUpdate } from '../fn/co-buying/co-buying-controller-update';
import { CoBuyingControllerUpdate$Params } from '../fn/co-buying/co-buying-controller-update';
import { CoBuyingDto } from '../models/co-buying-dto';
import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';

@Injectable({ providedIn: 'root' })
export class CoBuyingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `coBuyingControllerGetAll()` */
  static readonly CoBuyingControllerGetAllPath = '/api/co-buying/all';

  /**
   * 모든 공동 구매 조회.
   *
   * (관리자) 모든 공동 구매를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingControllerGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingControllerGetAll$Response(params?: CoBuyingControllerGetAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CoBuyingDto>>> {
    return coBuyingControllerGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 모든 공동 구매 조회.
   *
   * (관리자) 모든 공동 구매를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingControllerGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingControllerGetAll(params?: CoBuyingControllerGetAll$Params, context?: HttpContext): Observable<Array<CoBuyingDto>> {
    return this.coBuyingControllerGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CoBuyingDto>>): Array<CoBuyingDto> => r.body)
    );
  }

  /** Path part for operation `coBuyingControllerFindById()` */
  static readonly CoBuyingControllerFindByIdPath = '/api/co-buying/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingControllerFindById$Response(params: CoBuyingControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<CoBuyingDto>> {
    return coBuyingControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingControllerFindById(params: CoBuyingControllerFindById$Params, context?: HttpContext): Observable<CoBuyingDto> {
    return this.coBuyingControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoBuyingDto>): CoBuyingDto => r.body)
    );
  }

  /** Path part for operation `coBuyingControllerDelete()` */
  static readonly CoBuyingControllerDeletePath = '/api/co-buying/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingControllerDelete$Response(params: CoBuyingControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return coBuyingControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingControllerDelete(params: CoBuyingControllerDelete$Params, context?: HttpContext): Observable<void> {
    return this.coBuyingControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `coBuyingControllerUpdate()` */
  static readonly CoBuyingControllerUpdatePath = '/api/co-buying/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  coBuyingControllerUpdate$Response(params: CoBuyingControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<CoBuyingDto>> {
    return coBuyingControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  coBuyingControllerUpdate(params: CoBuyingControllerUpdate$Params, context?: HttpContext): Observable<CoBuyingDto> {
    return this.coBuyingControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoBuyingDto>): CoBuyingDto => r.body)
    );
  }

  /** Path part for operation `coBuyingControllerFindByName()` */
  static readonly CoBuyingControllerFindByNamePath = '/api/co-buying/name/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingControllerFindByName()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingControllerFindByName$Response(params: CoBuyingControllerFindByName$Params, context?: HttpContext): Observable<StrictHttpResponse<CoBuyingDto>> {
    return coBuyingControllerFindByName(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingControllerFindByName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingControllerFindByName(params: CoBuyingControllerFindByName$Params, context?: HttpContext): Observable<CoBuyingDto> {
    return this.coBuyingControllerFindByName$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoBuyingDto>): CoBuyingDto => r.body)
    );
  }

  /** Path part for operation `coBuyingControllerSearchOffset()` */
  static readonly CoBuyingControllerSearchOffsetPath = '/api/co-buying/search/offset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingControllerSearchOffset$Response(params: CoBuyingControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<CoBuyingDto>;
}>> {
    return coBuyingControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingControllerSearchOffset(params: CoBuyingControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto & {
'items'?: Array<CoBuyingDto>;
}> {
    return this.coBuyingControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<CoBuyingDto>;
}>): OffsetPaginationDto & {
'items'?: Array<CoBuyingDto>;
} => r.body)
    );
  }

  /** Path part for operation `coBuyingControllerSearchCursor()` */
  static readonly CoBuyingControllerSearchCursorPath = '/api/co-buying/search/cursor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingControllerSearchCursor$Response(params: CoBuyingControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto & {
'items'?: Array<CoBuyingDto>;
}>> {
    return coBuyingControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  coBuyingControllerSearchCursor(params: CoBuyingControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto & {
'items'?: Array<CoBuyingDto>;
}> {
    return this.coBuyingControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto & {
'items'?: Array<CoBuyingDto>;
}>): CursorPaginationDto & {
'items'?: Array<CoBuyingDto>;
} => r.body)
    );
  }

  /** Path part for operation `coBuyingControllerCreate()` */
  static readonly CoBuyingControllerCreatePath = '/api/co-buying';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coBuyingControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  coBuyingControllerCreate$Response(params: CoBuyingControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<CoBuyingDto>> {
    return coBuyingControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coBuyingControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  coBuyingControllerCreate(params: CoBuyingControllerCreate$Params, context?: HttpContext): Observable<CoBuyingDto> {
    return this.coBuyingControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoBuyingDto>): CoBuyingDto => r.body)
    );
  }

}
