/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CursorPaginationDto } from '../models/cursor-pagination-dto';
import { inquiryControllerAnswer } from '../fn/inquiry/inquiry-controller-answer';
import { InquiryControllerAnswer$Params } from '../fn/inquiry/inquiry-controller-answer';
import { inquiryControllerCreate } from '../fn/inquiry/inquiry-controller-create';
import { InquiryControllerCreate$Params } from '../fn/inquiry/inquiry-controller-create';
import { inquiryControllerDelete } from '../fn/inquiry/inquiry-controller-delete';
import { InquiryControllerDelete$Params } from '../fn/inquiry/inquiry-controller-delete';
import { inquiryControllerFindById } from '../fn/inquiry/inquiry-controller-find-by-id';
import { InquiryControllerFindById$Params } from '../fn/inquiry/inquiry-controller-find-by-id';
import { inquiryControllerGetAll } from '../fn/inquiry/inquiry-controller-get-all';
import { InquiryControllerGetAll$Params } from '../fn/inquiry/inquiry-controller-get-all';
import { inquiryControllerMy } from '../fn/inquiry/inquiry-controller-my';
import { InquiryControllerMy$Params } from '../fn/inquiry/inquiry-controller-my';
import { inquiryControllerSearchCursor } from '../fn/inquiry/inquiry-controller-search-cursor';
import { InquiryControllerSearchCursor$Params } from '../fn/inquiry/inquiry-controller-search-cursor';
import { inquiryControllerSearchOffset } from '../fn/inquiry/inquiry-controller-search-offset';
import { InquiryControllerSearchOffset$Params } from '../fn/inquiry/inquiry-controller-search-offset';
import { inquiryControllerUpdate } from '../fn/inquiry/inquiry-controller-update';
import { InquiryControllerUpdate$Params } from '../fn/inquiry/inquiry-controller-update';
import { InquiryDto } from '../models/inquiry-dto';
import { OffsetPaginationDto } from '../models/offset-pagination-dto';

@Injectable({ providedIn: 'root' })
export class InquiryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `inquiryControllerMy()` */
  static readonly InquiryControllerMyPath = '/api/inquiry/my';

  /**
   * 내 1:1 문의 목록.
   *
   * 내가 등록한 1:1 문의 목록을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerMy()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerMy$Response(params?: InquiryControllerMy$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InquiryDto>>> {
    return inquiryControllerMy(this.http, this.rootUrl, params, context);
  }

  /**
   * 내 1:1 문의 목록.
   *
   * 내가 등록한 1:1 문의 목록을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerMy$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerMy(params?: InquiryControllerMy$Params, context?: HttpContext): Observable<Array<InquiryDto>> {
    return this.inquiryControllerMy$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InquiryDto>>): Array<InquiryDto> => r.body)
    );
  }

  /** Path part for operation `inquiryControllerGetAll()` */
  static readonly InquiryControllerGetAllPath = '/api/inquiry/all';

  /**
   * 모든 문의 조회.
   *
   * (관리자) 모든 문의를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerGetAll$Response(params?: InquiryControllerGetAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InquiryDto>>> {
    return inquiryControllerGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * 모든 문의 조회.
   *
   * (관리자) 모든 문의를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerGetAll(params?: InquiryControllerGetAll$Params, context?: HttpContext): Observable<Array<InquiryDto>> {
    return this.inquiryControllerGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InquiryDto>>): Array<InquiryDto> => r.body)
    );
  }

  /** Path part for operation `inquiryControllerFindById()` */
  static readonly InquiryControllerFindByIdPath = '/api/inquiry/{id}';

  /**
   * 1:1 문의 상세 조회.
   *
   * 1:1 문의 상세 정보를 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerFindById$Response(params: InquiryControllerFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<InquiryDto>> {
    return inquiryControllerFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * 1:1 문의 상세 조회.
   *
   * 1:1 문의 상세 정보를 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerFindById(params: InquiryControllerFindById$Params, context?: HttpContext): Observable<InquiryDto> {
    return this.inquiryControllerFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<InquiryDto>): InquiryDto => r.body)
    );
  }

  /** Path part for operation `inquiryControllerDelete()` */
  static readonly InquiryControllerDeletePath = '/api/inquiry/{id}';

  /**
   * 1:1 문의 삭제.
   *
   * 1:1 문의를 삭제합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerDelete$Response(params: InquiryControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return inquiryControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * 1:1 문의 삭제.
   *
   * 1:1 문의를 삭제합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerDelete(params: InquiryControllerDelete$Params, context?: HttpContext): Observable<void> {
    return this.inquiryControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `inquiryControllerUpdate()` */
  static readonly InquiryControllerUpdatePath = '/api/inquiry/{id}';

  /**
   * 1:1 문의 수정.
   *
   * 1:1 문의를 수정합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inquiryControllerUpdate$Response(params: InquiryControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<InquiryDto>> {
    return inquiryControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * 1:1 문의 수정.
   *
   * 1:1 문의를 수정합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inquiryControllerUpdate(params: InquiryControllerUpdate$Params, context?: HttpContext): Observable<InquiryDto> {
    return this.inquiryControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<InquiryDto>): InquiryDto => r.body)
    );
  }

  /** Path part for operation `inquiryControllerSearchOffset()` */
  static readonly InquiryControllerSearchOffsetPath = '/api/inquiry/search/offset';

  /**
   * 1:1 문의 목록 조회.
   *
   * 1:1 문의 목록을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerSearchOffset()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerSearchOffset$Response(params: InquiryControllerSearchOffset$Params, context?: HttpContext): Observable<StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<InquiryDto>;
}>> {
    return inquiryControllerSearchOffset(this.http, this.rootUrl, params, context);
  }

  /**
   * 1:1 문의 목록 조회.
   *
   * 1:1 문의 목록을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerSearchOffset$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerSearchOffset(params: InquiryControllerSearchOffset$Params, context?: HttpContext): Observable<OffsetPaginationDto & {
'items'?: Array<InquiryDto>;
}> {
    return this.inquiryControllerSearchOffset$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffsetPaginationDto & {
'items'?: Array<InquiryDto>;
}>): OffsetPaginationDto & {
'items'?: Array<InquiryDto>;
} => r.body)
    );
  }

  /** Path part for operation `inquiryControllerSearchCursor()` */
  static readonly InquiryControllerSearchCursorPath = '/api/inquiry/search/cursor';

  /**
   * 1:1 문의 목록 조회.
   *
   * 1:1 문의 목록을 조회합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerSearchCursor()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerSearchCursor$Response(params: InquiryControllerSearchCursor$Params, context?: HttpContext): Observable<StrictHttpResponse<CursorPaginationDto & {
'items'?: Array<InquiryDto>;
}>> {
    return inquiryControllerSearchCursor(this.http, this.rootUrl, params, context);
  }

  /**
   * 1:1 문의 목록 조회.
   *
   * 1:1 문의 목록을 조회합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerSearchCursor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inquiryControllerSearchCursor(params: InquiryControllerSearchCursor$Params, context?: HttpContext): Observable<CursorPaginationDto & {
'items'?: Array<InquiryDto>;
}> {
    return this.inquiryControllerSearchCursor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CursorPaginationDto & {
'items'?: Array<InquiryDto>;
}>): CursorPaginationDto & {
'items'?: Array<InquiryDto>;
} => r.body)
    );
  }

  /** Path part for operation `inquiryControllerCreate()` */
  static readonly InquiryControllerCreatePath = '/api/inquiry';

  /**
   * 1:1 문의 등록.
   *
   * 1:1 문의를 등록합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inquiryControllerCreate$Response(params: InquiryControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<InquiryDto>> {
    return inquiryControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * 1:1 문의 등록.
   *
   * 1:1 문의를 등록합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inquiryControllerCreate(params: InquiryControllerCreate$Params, context?: HttpContext): Observable<InquiryDto> {
    return this.inquiryControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<InquiryDto>): InquiryDto => r.body)
    );
  }

  /** Path part for operation `inquiryControllerAnswer()` */
  static readonly InquiryControllerAnswerPath = '/api/inquiry/{id}/answer';

  /**
   * 1:1 문의 답변.
   *
   * 1:1 문의에 답변을 등록합니다.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inquiryControllerAnswer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inquiryControllerAnswer$Response(params: InquiryControllerAnswer$Params, context?: HttpContext): Observable<StrictHttpResponse<InquiryDto>> {
    return inquiryControllerAnswer(this.http, this.rootUrl, params, context);
  }

  /**
   * 1:1 문의 답변.
   *
   * 1:1 문의에 답변을 등록합니다.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inquiryControllerAnswer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inquiryControllerAnswer(params: InquiryControllerAnswer$Params, context?: HttpContext): Observable<InquiryDto> {
    return this.inquiryControllerAnswer$Response(params, context).pipe(
      map((r: StrictHttpResponse<InquiryDto>): InquiryDto => r.body)
    );
  }

}
