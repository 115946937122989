import { Component, computed, ElementRef, input, viewChild } from '@angular/core';
import { BaseInputComponent } from '../../common/base-input.component';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatePipe } from '@angular/common';
import dayjs from 'dayjs';

@Component({
    selector: 'app-input-date-v2',
    imports: [FormsModule, DatePipe],
    template: `
    <label>
      @if (label(); as label) {
      <span>{{ label }}</span>
      }
      <div
        class="flex items-center gap-2 transition-colors border-2 border-gray-300 group hover:border-primary-300 hover:focus-within:border-primary focus-within:border-primary px-3 text-sm rounded-md"
      >
        <input
          #inputRef
          class="w-full outline-none text-neutral-700 disabled:cursor-not-allowed py-1.5 text-sm ng-untouched ng-pristine ng-valid"
          type="date"
          [ngModel]="inputValue()"
          (ngModelChange)="handleModelChange($event)"
          [min]="min() | date : 'y-MM-dd'"
          [max]="max() | date : 'y-MM-dd'"
          [placeholder]="placeholder()"
          [spellcheck]="spellcheck()"
          [autocomplete]="autocomplete()"
          [autofocus]="autofocus()"
        />
      </div>
    </label>
  `,
    styleUrl: '../../common/input.common.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: InputDateComponentV2,
            multi: true,
        },
    ]
})
export class InputDateComponentV2 extends BaseInputComponent<Date> {
  min = input<string | Date>();
  max = input<string | Date>();

  inputValue = computed(() => dayjs(this.value()).format('YYYY-MM-DD'));

  handleModelChange(value: string) {
    this.value.set(dayjs(value).toDate());
  }
}
